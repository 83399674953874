<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header.vue";
import axios from "axios";
import {required} from "vuelidate/lib/validators";
import {errorCatcher} from "@/helpers/error-catcher";
import Swal from "sweetalert2";
import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";

/**
 * Categories Component
 */
export default {
  components: {
    CustomCardSubtitle,
    Layout,
    PageHeader
  },

  data() {
    return {
      submitted: false,

      categories: null,
      allCategories: null,
      category: {
        adultOnly: false
      },
      selectedSubCategory: {},
      checkedCategories: [],

      showModal: false
    };
  },

  validations: {

    category: {
      name: {required}
    }

  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('menuitems.admin.list.categories'),
          active: true
        }
      ]
    },

    async loadCategoriesWithoutParents() {
      const result = await axios.get(`/category`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {}
      });

      this.categories = result.data
    },

    async loadCategories() {
      const result = await axios.get(`/category/list`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {}
      });

      this.allCategories = result.data
    },

    loadParentCategory(clickedCategory) {
      if (this.checkedCategories.includes(clickedCategory.id)) {
        return;
      }

      this.checkedCategories.push(clickedCategory.id)

      axios.get(`/category/parent/${clickedCategory.id}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
      }).then(({data}) => {
        for (const parentCategory of data) {
          if (parentCategory.parent !== null) {
            if (clickedCategory.id === parentCategory.parent) {
              if (!clickedCategory.categories) {
                clickedCategory.categories = [];
              }

              clickedCategory.categories = clickedCategory.categories.filter(element => element.id !== parentCategory.id)
              clickedCategory.categories.push(parentCategory)
            }
          }
        }

        this.$root.$emit('refresh-tree')
      });
    },

    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.showModal = false;
      this.category = {};
      this.selectedSubCategory = {};
    },

    createOrEditCategory() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const json = JSON.stringify(this.category);

      axios.put(`/category`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        this.$bvToast.toast(this.$t(this.category.id ? "categories.edit.success" : "categories.create.success"), {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        setTimeout(() => this.$router.go(this.$router.currentRoute), 350)
        this.hideModal()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    editCategory(category) {
      this.category = Object.assign({}, category);
      delete this.category.categories

      if (this.category.parent !== null) {
        const parentCategory = this.getCategory(this.category.parent)
        if (parentCategory !== null) {
          this.selectSubCategory(parentCategory)
        }
      }

      this.showModal = true
    },

    deleteCategory(category) {
      this.submitted = true;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2"
        },
        buttonsStyling: false
      });

      swalWithBootstrapButtons.fire({
        title: this.$t('alert.title'),
        text: this.$t('alert.message'),
        icon: "warning",
        confirmButtonText: this.$t('alert.yes'),
        cancelButtonText: this.$t('alert.no'),
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          axios.delete(`/category/${category.id}`, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json'
            },
            data: {}
          }).then(() => {
            this.$bvToast.toast(this.$t('categories.delete.success'), {
              title: this.$t('message.success'),
              variant: 'success',
              solid: true
            });

            setTimeout(() => this.$router.go(this.$router.currentRoute), 350)
          }).catch((error) => {
            errorCatcher.catchErrors(error)
            this.submitted = false;
          })
        }
      })
    },

    getCategoryName(id) {
      for (const category of this.allCategories) {
        if (category.id === id) {
          return category.name;
        }
      }

      return "";
    },

    getCategory(id) {
      for (const category of this.categories) {
        if (category.id === id) {
          return category;
        }
      }

      return null;
    },

    selectSubCategory(subCategory) {
      this.selectedSubCategory = subCategory;
      this.category.parent = subCategory.id;
    },

    deselectSubCategory() {
      this.selectedSubCategory = "";
      this.category.parent = null;
    }

  },

  computed: {

    getCategories() {
      return this.categories;
    }

  },

  async created() {
    try {
      await this.loadCategoriesWithoutParents()
      await this.loadCategories()
    } catch (error) {
      console.log(error)
    }
  },

  mounted() {
    this.$root.$on('ecat-click-category', (clickedCategory) => {
      this.loadParentCategory(clickedCategory)
    })

    this.$root.$on('ecat-click-edit', (clickedCategory) => {
      this.editCategory(clickedCategory)
    })

    this.$root.$on("ecat-click-delete", (clickedCategory) => {
      this.deleteCategory(clickedCategory)
    })
  },

  beforeDestroy() {
    this.$root.$off("ecat-click-category");
    this.$root.$off("ecat-click-edit");
    this.$root.$off("ecat-click-delete");
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('menuitems.admin.list.categories')" :items="getItems()"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <custom-card-subtitle title="Akcje"/>

              <div class="col-xl-12">
                <a class="clickable-element btn btn-success" @click="showModal = true">
                  <i class="mdi mdi-plus mr-2"></i> {{ $t('categories.create.button') }}
                </a>
              </div>
            </div>

            <template v-if="categories">
              <template v-if="categories.length !== 0">
                <div class="py-3">
                  <category-parent class="tree" :show-product-count="true" :trees="getCategories"></category-parent>
                </div>
              </template>
              <template v-else>
                <h5 class="text-center py-3">{{ $t('message.no-elements') }}</h5>
              </template>
            </template>
            <template v-else>
              <div class="text-center py-3">
                <h5>{{ $t('message.loading') }}</h5>
                <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <b-modal
        id="modal-1"
        v-model="showModal"
        :title="$t(category.id ? 'categories.edit.title' : 'categories.create.title')"
        title-class="font-18"
        hide-footer
        @hide="hideModal"
        @esc="hideModal">
      <form>

        <div class="form-group">
          <label for="name">{{ $t('message.name') }}</label>
          <input id="name" v-model="category.name" type="text" class="form-control"
                 :class="{ 'is-invalid': submitted && $v.category.name.$error }"/>
          <div v-if="submitted && !$v.category.name.required" class="invalid-feedback">{{ $t('message.required') }}
          </div>
        </div>

        <div class="form-group">
          <label for="sub-category">{{ $t('categories.table.leaf') }}</label>

          <ecat-multiselect
              @change="value => category.parent = value"
              view-id="name"
              save-id="id"
              :can-unselect="true"
              placeholder="Wybierz kategorie"
              fetch-one-url="/category"
              load-url="/category/list/pagination"
              query-url="/category/by-name"
              param="name"
          />

          <!--          <multiselect-->
          <!--              id="sub-category"-->
          <!--              :value="selectedSubCategory"-->
          <!--              @select="value => selectSubCategory(value)"-->
          <!--              @remove="deselectSubCategory"-->
          <!--              :options="allCategories ? allCategories : []"-->
          <!--              :searchable="true"-->
          <!--              :placeholder="$t('categories.modal.select-subcategory')"-->
          <!--              :custom-label="value => getCategoryName(value.id)">-->
          <!--            <span slot="noOptions">{{ $t('message.list-is-empty')}}</span>-->

          <!--          </multiselect>-->
        </div>

        <div class="form-group">
          <div class="custom-control custom-checkbox mb-3">
            <input id="adult-only" v-model="category.adultOnly" type="checkbox" class="custom-control-input"/>
            <label for="adult-only" class="custom-control-label">{{ $t('categories.table.adult-only') }}</label>
          </div>
        </div>

        <div class="form-group">
          <label for="margin">Narzut</label>
          <input v-model.number="category.profitMargin" type="number" class="form-control custom-number-with-gap"
                 step="0.1"/>
        </div>

        <div class="text-center">
          <b-button @click="createOrEditCategory" variant="success">
            {{ $t(category.id ? 'categories.edit.button' : 'categories.create.button') }}
          </b-button>
          <b-button class="ml-1" variant="danger" @click="hideModal">{{ $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>

  </Layout>
</template>

<style>
.tree {
  --spacing: 1.5rem;
  --radius: 10px;
}

.tree li {
  display: block;
  position: relative;
  padding-left: calc(2 * var(--spacing) - var(--radius) - 2px);
}

.tree ul {
  margin-left: calc(var(--radius) - var(--spacing));
  padding-left: 0;
}

.tree ul li {
  border-left: 2px solid #ddd;
}

.tree ul li:last-child {
  border-color: transparent;
}

.tree ul li::before {
  content: '';
  display: block;
  position: absolute;
  top: calc(var(--spacing) / -2);
  left: -2px;
  width: calc(var(--spacing) + 2px);
  height: calc(var(--spacing) + 1px);
  border: solid #ddd;
  border-width: 0 0 2px 2px;
}

.tree summary {
  display: block;
  cursor: pointer;
}

.tree summary::marker,
.tree summary::-webkit-details-marker {
  display: none;
}

.tree summary:focus {
  outline: none;
}

.tree summary:focus-visible {
  outline: 1px dotted #000;
}

.tree li::after,
.tree summary::before {
  content: '';
  display: block;
  position: absolute;
  top: calc(var(--spacing) / 2 - var(--radius));
  left: calc(var(--spacing) - var(--radius) - 1px);
  width: calc(2 * var(--radius));
  height: calc(2 * var(--radius));
  border-radius: 50%;
  background: #ddd;
}

.tree summary::before {
  z-index: 1;
  background: #696 url('../../../assets/images/expand-collapse.svg') 0 0;
}

.tree details[open] > summary::before {
  background-position: calc(-2 * var(--radius)) 0;
}
</style>